@import url(/style/css/swiper-pagination.css);

.box[data-v-16d906a5]{
}
.box .box-header[data-v-16d906a5]{
   width:100%;
}
.box .box-header .title[data-v-16d906a5]{
  font-size: 5.333vw;
    font-family: qsdm,PingFangSC-Semibold;
    font-weight: 700;
    padding:0 10px;
    color: #212831;
}
#home[data-v-16d906a5]{
  display: flex;
  flex-direction: column;
}
.category[data-v-16d906a5]{
    height: 38vw;
    display: flex;
    justify-content:center;
    align-items: flex-start;
    flex-wrap: wrap;
    padding: 10px;
    overflow: hidden;
}
.category .item[data-v-16d906a5]{
    height: 20vw;
    width: 20vw;
    border:solid 1px #eee;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 1.067vw 2.667vw 0 rgb(0 0 0 / 6%);
    border-radius: 2.133vw;
}
.banner[data-v-16d906a5]{
    padding: 20px 20px 0px 20px;
    box-sizing: border-box;
    height: 200px;
    display: flex;
    width: 100%;
    justify-content:center;
    align-items: center;
}
.article-warpper[data-v-16d906a5]{
    display: flex;
    justify-content:center;
    align-items: flex-start;
    flex-direction:row;
    flex-wrap: wrap;
    width: 100%;
    padding: 10px;
    padding-left: 0;
    box-sizing: border-box;
    width: 100%;
    padding-bottom: 7.2vh;
}
.article-warpper .article[data-v-16d906a5]{
    background: #fff;
    box-shadow: 0 1.067vw 2.667vw 0 rgb(0 0 0 / 6%);
    border-radius: 2.133vw;
    margin-bottom: 10px;
    padding: 5px;
    box-sizing: border-box;
    width: 47%;
    height: 13rem;
    margin-left: 10px;
    overflow: hidden;
}
.article-warpper .article[data-v-16d906a5]:first-of-type{
  margin-left: 0px;
}
.article-warpper .article .title[data-v-16d906a5]{
  /* font-size: 16px;
    font-weight: 600;
    margin-top: 10px; */
    font-size: 0.8rem;
    font-weight: 700;
    margin-top: 10px;
    line-height: 1.5;
}
.article-warpper .article .subtitle[data-v-16d906a5]{
  /* font-size: 16px;
    font-weight: 600;
    margin-top: 10px; */
    font-size: 0.6rem;
    font-weight: 500;
    margin-top: 0.5vh;
    line-height: 1;
}
.article-warpper .article .coverimage[data-v-16d906a5]{
    width: 100%;
    border-radius: 2.133vw;
    -o-object-fit: contain;
    object-fit: cover;
    height: 100%;
}


/*# sourceMappingURL=chunk-26f73c0b.1d64fc6c.css.map*/